/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';
import { CdtoButton } from '../../components/CdtoButton';

interface IProps {
  title: string;
}

const CdtoHeader: FC<IProps> = ({ title }) => {
  const { formatMessage } = useIntl();

  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={classNames(styles.wrap)}
      style={{
        backgroundImage: `url('/images/directions/cdto/cdto-header-1.png')`,
      }}
    >
      <div className={classNames(root.breadcrumbs)}>
        <Breadcrumbs title={title} />
        <div className={styles.contacts}>
          <div>
            <a
              href={`tel:${formatMessage({
                id: 'cases.cdto.header.phone',
              })}`}
            >
              {t('cases.cdto.header.phone')}
            </a>
          </div>
          <div>
            <a
              href={`mailto:${formatMessage({
                id: 'cases.cdto.header.email',
              })}`}
            >
              {t('cases.cdto.header.email')}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.content}>
        <div className={styles.text}>
          <img
            src="/images/directions/finance/logo_Icerock.svg"
            alt="icerock"
          />
          <h1 className={styles.title}>{t('cases.cdto.header.title')}</h1>
          <p className={styles.desc}>{t('cases.cdto.header.desc')}</p>
          <CdtoButton onClick={scrollToForm}>
            {t('cases.cdto.header.button')}
          </CdtoButton>
        </div>
      </div>
    </div>
  );
};

export { CdtoHeader };
