/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { CdtoButton } from '../../components/CdtoButton';

const CtdoPrice: FC = () => {
  const prefix = 'cases.cdto.price';

  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.sectionWrap}>
      <div className={styles.wrap}>
        <h2>{t(`${prefix}.title`)}</h2>
        <p className={styles.desc}>{t(`${prefix}.desc`)}</p>
        <div className={styles.offer}>
          <div className={styles.icon}>
            <img src="/images/directions/finance/icons/fire.svg" alt="" />
          </div>
          <div className={styles.content}>
            <h3>{t(`${prefix}.offer.title`)}</h3>
            <p>{t(`${prefix}.offer.desc`)}</p>
          </div>
          <button onClick={scrollToForm} className={styles.link}>
            <img src="/images/directions/finance/icons/btn_arrow.svg" alt="" />
          </button>
          <div className={styles.btnMobileContainer}>
            <CdtoButton onClick={scrollToForm}>
              {t(`cases.finance.header.button`)}
            </CdtoButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtdoPrice;
