import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const CdtoOurStack: FC = () => {
  const prefix = 'cases.cdto.ourStack';

  const cards = [
    ['/images/directions/cdto/analytics.svg'],
    ['/images/directions/cdto/design.svg'],
    ['/images/directions/cdto/prototype.svg'],
    ['/images/directions/cdto/frontend.svg'],
    ['/images/directions/cdto/backend.svg'],
    ['/images/directions/cdto/ios.svg', '/images/directions/cdto/android.svg'],
  ];

  return (
    <section className={styles.wrap}>
      <h2 className={styles.centerH2}>{t(`${prefix}.title`)}</h2>
      <div className={styles.container}>
        {cards.map((card, index) => (
          <div key={`${index + 1}`} className={styles.card}>
            <div className={styles.cardHeader}>
              <div className={styles.cardHeader__iconsWrap}>
                {card.map((img) => (
                  <img key={img} src={img} alt={img} draggable={false} />
                ))}
              </div>
              <span className={styles.cardHeader__number}>{index + 1}</span>
            </div>
            <div className={styles.cardBody}>
              <div className={styles.cardBody__title}>
                <span>{t(`${prefix}.cards.${index}.title`)}</span>
                <span className={styles.dot} />
                <span className={styles.cardBody__subtitle}>
                  {t(`${prefix}.cards.${index}.specialists`)}
                </span>
              </div>
              <div>{t(`${prefix}.cards.${index}.body`)}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CdtoOurStack;
