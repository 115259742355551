import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const CtdoOurProjects: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabPrefix = `cases.cdto.ourwork.tab_${activeTab}`;

  const cases = [
    {
      title: 'cases.cdto.ourwork.tab_0.title',
      screen: '/images/directions/cdto/Case-1.png',
    },
    {
      title: 'cases.cdto.ourwork.tab_1.title',
      screen: '/images/directions/cdto/Case-2.png',
      url: '/cases/projects/leroy-merlin',
    },
    {
      title: 'cases.cdto.ourwork.tab_2.title',
      screen: '/images/directions/cdto/Case-3.png',
    },
    {
      title: 'cases.cdto.ourwork.tab_3.title',
      screen: '/images/directions/cdto/Case-4.png',
      url: '/cases/projects/vtb/',
    },
  ];

  const featuresOnTab = [
    [...Array(5)],
    [...Array(5)],
    [...Array(2)],
    [...Array(2)],
  ];

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>
        <div className={styles.buttonsContainer}>
          {cases.map((item, index) => (
            <button
              key={item.title}
              type="button"
              className={`${styles.button} ${
                activeTab === index ? styles.button__active : ''
              }`}
              onClick={() => setActiveTab(index)}
            >
              {t(item.title)}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.tabContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.desc}>{t(`${tabPrefix}.desc_0`)}</div>
          <div className={styles.desc}>{t(`${tabPrefix}.desc_1`)}</div>
          <div className={styles.featuresContainer}>
            {featuresOnTab[activeTab].map((item, index) => (
              <div key={`feature-${index + 1}`} className={styles.feature}>
                <div className={styles.feature__text}>
                  {t(`${tabPrefix}.features.${index}.title`)}
                </div>
              </div>
            ))}
          </div>
          {cases[activeTab].url ? (
            <div className={styles.desktopLink}>
              <a href={cases[activeTab].url}>
                {t('cases.cdto.ourwork.linkName')}
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="#D761AD"
                  />
                </svg>
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={styles.rightContainer}>
          <img src={cases[activeTab].screen} alt="" />
          {cases[activeTab].url ? (
            <div className={styles.mobileLink}>
              <a href={cases[activeTab].url}>
                {t('cases.cdto.ourwork.linkName')}
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                    fill="#D761AD"
                  />
                </svg>
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default CtdoOurProjects;
