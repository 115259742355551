/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const CdtoHowWeWork: FC = () => {
  const prefix = 'cases.cdto.howWeWork';

  return (
    <div className={styles.sectionWrap}>
      <div className={styles.wrap}>
        <h2>{t(`${prefix}.title`)}</h2>
        <div className={styles.stepContainer}>
          {[...Array(6)].map((item, index) => (
            <div key={`step-${index + 1}`} className={styles.step}>
              <div className={styles.number}>{index + 1}</div>
              <div className={styles.content}>
                {t(`${prefix}.steps.${index}`)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CdtoHowWeWork;
