/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { ReviewCard } from '../../../../reviews/ReviewCard';
import { Slider } from '../../../../common/Slider';
import { IReviewsQuery } from '../../../../../queries/reviews/types';
import { t } from '../../../../../i18n';

type IProps = {
  reviews: IReviewsQuery['reviews']['edges'];
  locale: string;
};

const prefix = 'cases.cdto.reviewsSlider';

const CdtoReviews: FC<IProps> = ({ reviews, locale }) => (
  <div className={styles.wrap}>
    <h2>{t(`${prefix}.title`)}</h2>
    <Slider
      sliderProps={{
        items: 1,
        mouseDrag: true,
        slideBy: 'page',
        gutter: 20,
        lazyload: false,
        swipeAngle: 45,
        loop: false,
        autoHeight: true,
        responsive: {
          0: { controls: true, nav: false },
          769: { controls: true, nav: true },
        },
      }}
      containerClassName={styles.sliderContainer}
      className={styles.slider}
      color="#0062FF"
    >
      {reviews.map(({ node }, i) => (
        <div key={i} className={styles.item}>
          <ReviewCard locale={locale} node={node} key={i} />
        </div>
      ))}
    </Slider>
    <div className={styles.urlwrap}>
      <a href="/reviews/">{t(`${prefix}.button`)}</a>
    </div>
  </div>
);

export { CdtoReviews };
