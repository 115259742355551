/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const CdtoSolutions: FC = () => {
  const prefix = 'cases.cdto.solutions';

  const directions = [
    {
      list: [...Array(3)],
    },
    {
      list: [...Array(2)],
    },
    {
      list: [...Array(3)],
    },
  ];

  const partners = [
    '/images/directions/cdto/datana-logo.svg',
    '/images/directions/cdto/leroy-logo.svg',
    '/images/directions/cdto/vtb-logo.svg',
    '/images/directions/cdto/cft-logo.svg',
    '/images/directions/cdto/veka-logo.svg',
  ];

  return (
    <div className={classNames(styles.wrap)}>
      <h2>{t(`${prefix}.title`)}</h2>
      <div className={classNames(styles.block)}>
        <h2 className={classNames(styles.title)}>
          {t(`${prefix}.part_0.title`)}
        </h2>
        <div className={classNames(styles.directionWrapper)}>
          {[...Array(3)].map((item, index) => (
            <div
              className={classNames(styles.direction)}
              key={`list-${index + 1}`}
            >
              <div className={classNames(styles.directionHeaderWrapper)}>
                <span className={classNames(styles.line)} />
                <div className={classNames(styles.title)}>
                  {t(`${prefix}.part_0.list.${index}.title`)}
                </div>
              </div>
              <div className={classNames(styles.directionBodyWrapper)}>
                <div className={styles.stepsContainer}>
                  <div className={styles.step}>
                    <ul className={styles.content}>
                      {directions[index].list.map((dir, idx) => (
                        <li key={`step-solutions-${idx + 1}`}>
                          {t(`${prefix}.part_0.list.${index}.features.${idx}`)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classNames(styles.block)}>
        <h2 className={classNames(styles.title)}>
          {t(`${prefix}.part_1.title`)}
        </h2>
        <div className={classNames(styles.partnersWrapper)}>
          {[...Array(5)].map((item, index) => (
            <div
              key={`list-${index + 1}`}
              className={classNames(styles.partnerBlock)}
            >
              <img src={partners[index]} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { CdtoSolutions };
