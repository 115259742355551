/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const CdtoPros: FC = () => {
  const prefix = 'cases.cdto.pros';

  return (
    <div className={classNames(styles.wrap)}>
      <h2>{t(`${prefix}.title`)}</h2>
      <div className={styles.stepsContainer}>
        <div className={styles.step}>
          <ul className={styles.content}>
            {[...Array(3)].map((item, index) => (
              <li key={`list-${index + 1}`}>{t(`${prefix}.list.${index}`)}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { CdtoPros };
