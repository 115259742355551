/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  onClick?: () => void;
  bigSize?: boolean;
  submit?: boolean;
}

const stylesBig = {
  maxWidth: '490px',
  width: '100%',
};

const CdtoButton: FC<IProps> = ({
  children,
  bigSize,
  submit,
  onClick = () => {},
}) => (
  <button
    className={styles.button}
    onClick={onClick}
    style={bigSize ? stylesBig : undefined}
    type={submit ? 'submit' : undefined}
  >
    {children}
  </button>
);

export { CdtoButton };
